<ng-container *ngIf="isMultiple || document?.medias?.length === 0">
  <div id="file_input_{{document?.type}}" class="file__input" appDropFile (fileDropped)="onFileDropped($event)">
    <ion-icon class="cloud-icon" name="cloud-upload-outline"></ion-icon>
    <p class="d-block d-md-none">
      <span>{{ 'filePicker.pick_mobile' | translate }}</span>
    </p>
    <p class="d-none d-md-block">
      {{ 'filePicker.drag' | translate }} <span>{{ 'filePicker.pick_desktop' | translate }}</span>
    </p>
    <p class="max-size">{{ 'filePicker.size' | translate }}</p>
  </div>
</ng-container>

<div *ngFor="let m of document?.medias" class="file__link">
  <a [href]="m.path" target="_blank" class="d-none d-md-flex"> {{(m.originalName)? (m.originalName | slice:0:35)+'...':(m.originalName)}}</a>
  <a [href]="m.path" target="_blank" class="d-flex d-md-none"> {{(m.originalName)? (m.originalName | slice:0:15)+'...':(m.originalName)}}</a>
  <ion-button (click)="delete(m.id)" color="danger" *ngIf="m.deletable || document.status === 'waiting_approval' || document.status === 'invalidated' || document.status === 'file_error'">
    <i class="far fa-trash"></i>
  </ion-button>
</div>
<div class="uploads">
  <app-file-upload
    *ngFor="let upload of uploads; let index = index"
    (fileUploaded)="onFileUploaded($event)"
    (deleteUploaded)="deleteUploaded($event)"
    [upload]="upload"
    [light]="true"
    [delete]="true"
    [index]="index"
  ></app-file-upload>
</div>
