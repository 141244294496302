import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyComponent implements OnInit {
  @Input() public icon!: string;

  @Input() public title!: string;

  @Input() public url!: string;

  @Input() public label!: string;

  @Input() public description!: string;

  constructor() {}

  ngOnInit() {}
}
