<ng-container *ngFor="let data of bonds">
  <div class="bond desktop" [class.little]="comingFromTransaction">
    <div class="column">
      {{ data.createdAt | amDateFormat: 'DD/MM/YYYY' }}
    </div>
    <div class="column mail">
      {{ data.email }}
    </div>
    <div class="column">
      <span [class]="'state ' + data.state">
        {{ 'state.' + data.state | translate }}
      </span>
    </div>
    <div class="column p1">{{ data?.product?.amount | number: '1.2-2' }}€</div>
    <div class="column">
      {{ data?.product?.reference }}
    </div>
    <div class="column retry__container" *ngIf="!comingFromTransaction">
      <div class="retry">
        {{ data.retry }}
      </div>
    </div>
    <div class="column">
      <ion-button class="action" [routerLink]="['/owner', 'bonds', data.id]">
        {{ 'globals.details' | translate }}
      </ion-button>
    </div>
  </div>
  
  <div [routerLink]="['/owner', 'bonds', data.id]" class="bond data tablet">
    <div class="line">
      <div class="column">
        {{ data.createdAt | amDateFormat: 'DD/MM/YYYY' }}
      </div>
      <div class="column state-column">
        <span [class]="'state ' + data.state">
          {{ 'state.' + data.state | translate }}
        </span>
      </div>
      <div>
        <i class="far fa-ellipsis-v"></i>
      </div>
    </div>
    <div class="line">
      <div class="column mail">
        {{ data.email }}
      </div>
      <div class="column retry-column" *ngIf="!comingFromTransaction">
        <div class="retry">
          {{ data.retry }}
        </div>
      </div>
    </div>
    <div class="line last">
      <div class="column amount">{{ data?.product?.amount | number: '1.2-2' }}€</div>
    </div>
  </div>
  
</ng-container>