import { Injectable } from '@angular/core';
import {AbstractRequest} from '@shared/requests/AbstractRequest';
import Property from '@shared/models/Property';
import {HttpParams} from '@angular/common/http';
import Count from '@shared/models/Count';

@Injectable({
  providedIn: 'root'
})
export class PropertyServiceService extends AbstractRequest{
  public create(property: Property): Promise<Property> {
    return this.http.post<Property>(`${this.endpoint}/property`, property).toPromise();
  }

  public update(property: Property): Promise<Property> {
    return this.http.put<Property>(`${this.endpoint}/property/${property.id}`, property).toPromise();
  }

  public delete(id: number): Promise<void> {
    return this.http.delete<void>(`${this.endpoint}/property/${id}`).toPromise();
  }

  public fetch(id: number): Promise<Property> {
    return this.http.get<Property>(`${this.endpoint}/property/${id}`).toPromise();
  }

  public fetchByName(name: string): Promise<Property[]> {
    return this.http.get<Property[]>(`${this.endpoint}/property/name?name=${name}`).toPromise();
  }

  public fetchAll(
      page?: number,
      items?: number,
      sortBy?: string | null,
      sortDirection?: string | null,
      search?: string | null
  ): Promise<Property[]> {
    const names = ['page', 'items', 'sortBy', 'sortDirection', 'search'];
    const args = this.formatArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
        .get<Property[]>(`${this.endpoint}/property`, {
          params,
        })
        .toPromise();
  }

  public count(
      sortBy?: string,
      sortDirection?: string,
      search?: string,
  ): Promise<Count> {
    const names = ['sortBy', 'sortDirection', 'search'];
    const args = this.formatArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
        .get<Count>(`${this.endpoint}/property/count`, {
          params,
        })
        .toPromise();
  }
}
