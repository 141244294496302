<div class="file h-auto">
  <div class="file__main">
    <div class="d-flex justify-content-between justify-content-lg-start">
      <p [ngClass]="{ 'label-error': errors.includes(document.type)}">
        {{ documentService.trad(document.type) }}
      <span class="infos">
        <app-informations-component
                [otherClass]="document.type"
                *ngIf="document.type === 'PROOF_OF_ID' || document.type === 'identity_card' ||
                document.type === 'rc_resort' || document.type === 'driving_license'"
                [text]="'document.information.' + document.type | translate">
        </app-informations-component>
      </span>
      </p>
    </div>
    <div class="action mt-2 mt-lg-0">
      <div class="label-error" *ngIf="errors.includes(document.type)">
        {{ 'state.file_required' | translate }}
      </div>
      <p class="state" [class]="document.status">
        <span [ngClass]="{ error: document.isValid === false || document.status === documentStatusEnum.INVALIDATED || document.status === documentStatusEnum.FILE_ERROR,
        success: document.status === documentStatusEnum.VALIDATED}"
              *ngIf="document.status !== documentStatusEnum.PROVIDE && fileDropedStatus !== documentStatusEnum.DROPED">
          {{
            'state.' + (document.status === documentStatusEnum.WAITING_APPROVAL ? fileDropedStatus : document.status)
              | translate
          }}
        </span>
        <span [ngClass]="{ error: document.isValid === false }" *ngIf="document.status === documentStatusEnum.PROVIDE && document.isValid === false">
          {{ 'state.file_required' | translate }}
        </span>
      </p>
    </div>
  </div>
  <app-file-input [document]="document" [isMultiple]="isMultiple" (fileUploaded)="fileUploaded.emit($event)">
  </app-file-input>
</div>
