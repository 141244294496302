import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-informations-component',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss'],
})
export class InformationsComponent implements OnInit {
  @Input() public text = '';
  @Input() public otherClass = '';
  constructor() { }

  ngOnInit() {}

}
