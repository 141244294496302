import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';
import CredentialToken from '../models/CredentialToken';

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  protected credential!: CredentialToken | null;

  protected helper: JwtHelperService;

  protected username!: string;

  protected firstName!: string;

  protected roles: string[] = [];

  protected verified!: boolean;


  constructor(protected storage: Storage) {
    this.helper = new JwtHelperService();
  }

  public loadToken(): Promise<void> {
    return this.storage.get(TOKEN_KEY).then((token: string) => {
      this.credential = new CredentialToken();
      this.credential.token = token;
      this.decodeToken();
    });
  }

  public setToken(credential: CredentialToken): void {
    this.credential = credential;
    this.storage.set(TOKEN_KEY, credential.token);
    this.decodeToken();
  }

  public getToken(): Promise<string | null> {
    return this.loadToken().then(() => {
      if (!this.credential || !this.credential.token) {
        return null;
      }
      return this.credential.token;
    });
  }

  public isExpired(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.credential && this.credential.token) {
        resolve(this.helper.isTokenExpired(this.credential.token));
      }

      this.storage
        .get(TOKEN_KEY)
        .then((token: string) => {
          this.credential = new CredentialToken();
          this.credential.token = token;
          this.decodeToken();
          resolve(this.helper.isTokenExpired(this.credential.token));
        })
        .catch(() => {
          resolve(true);
        });
    });
  }

  public removeToken(): void {
    this.credential = null;
    this.storage.remove(TOKEN_KEY);
  }

  public getUsername(): string {
    return this.username;
  }

  public getFirstName(): string {
    return this.firstName;
  }

  public setFirstName(name: string): void {
    this.firstName = name;
  }

  public getRoles(): string[] {
    return this.roles;
  }

  public isVerified(): boolean {
    return this.verified;
  }


  protected decodeToken(): void {
    if (!this.credential || !this.credential.token) {
      return;
    }

    const decode = this.helper.decodeToken(this.credential.token);
    this.username = decode.username;
    this.verified = decode.verified;
    this.firstName = decode.firstName;
    this.roles = decode.roles;

  }
}
