import { Component, Input, OnInit } from '@angular/core';
import Bond from '@shared/models/Bond';
import { StatusService } from '@shared/translations/status.service';

@Component({
  selector: 'app-bond',
  templateUrl: './bond.component.html',
  styleUrls: ['./bond.component.scss'],
})
export class BondComponent implements OnInit {
  @Input() public bond!: Bond | Bond[];

  @Input() public comingFromTransaction = false;

  bonds: Bond[] = [];

  constructor(public status: StatusService) {}

  ngOnInit() {
    if (Array.isArray(this.bond)) {
      this.bonds = this.bond;
    } else {
      this.bonds.push(this.bond);
    }
  }
}
