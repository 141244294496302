<div class="content">
  <div id="top"></div>
  <div class="Property" *ngIf="property">
    <div class="Property__Header">
      <div class="breadcrumb justify-content-between">
        <p>
          <a [routerLink]="['/owner', 'properties']">{{ 'globals.myRequest' |
            translate }}</a>
          <i class="far fa-chevron-left"></i>
          <span>{{ 'pages.createAsset.editAsset' | translate }}</span>
        </p>
      </div>
    </div>
    <div class="Property__onglet d-flex">
      <h3 [ngClass]="{ active: onglet === 1 }" (click)="setOnglet(1)">{{'pages.assets.description'
        | translate}}</h3>
      <h3 [ngClass]="{ active: onglet === 2 }" (click)="setOnglet(2)">
        {{ 'pages.assets.bond_Link_By_Request' | translate }}
      </h3>
    </div>
    <div class="Property__DetailsArea" *ngIf="onglet === 1">
      <form #PropertyIform="ngForm" class="Property__Form">
        <div class="Property__Form__Title">
          <h1>{{ 'pages.assets.property_information' | translate }}</h1>
        </div>
        <div class="error_block" *ngIf="error">
          <i class="fas fa-exclamation-triangle"></i>
          <p>{{ 'pages.payment.account.error' | translate }}</p>
        </div>
        <div class="Property__Form__InputArea">
          <label>{{'globals.parmanentLink' | translate}}<sup>*</sup></label>
          <div class="data">
            <p>{{link + property?.link}}</p>
            <div class="action">
              <ion-button (click)="onCopy(link + property?.link)"
                class="button__rounded light-blue">
                <i class="far fa-copy"></i>
              </ion-button>
              <ion-button target="_blank" [href]="link + property?.link"
                class="button__rounded light-green">
                <i class="far fa-external-link-alt"></i>
              </ion-button>
            </div>
          </div>
        </div>
        <div class="Property__Form__TypeArea">
          <div class="Property__Form__TypeArea__Title">
            <p>{{'pages.assets.typeOfAssets' | translate}}<sup>*</sup></p>
            <span class="infos">
              <app-informations-component
                [text]="'pages.assets.propertyType' | translate"></app-informations-component>
            </span>
          </div>
          <div class="bool">
            <span
              (click)="property.type = PropertyType.UNIQUE"
              [ngClass]="{ checked: property.type === PropertyType.UNIQUE }">{{
              'pages.assets.uniqueAssets' | translate }}</span>
            <span
              (click)="property.type = PropertyType.MULTIPLE"
              [ngClass]="{ checked: property.type === PropertyType.MULTIPLE }">{{
              'pages.assets.multipleAssets' | translate }}</span>
          </div>
          <div class="invalid-feedback" *ngIf="frontErrors.type != ''">{{
            frontErrors.type }}</div>
        </div>
        <div class="Property__Form__PrimaryArea">
          <div class="Property__Form__InputArea">
            <label for="name">{{ 'globals.assetName' | translate }} <sup>*</sup></label>
            <input
              type="text"
              name="name"
              id="name"
              [(ngModel)]="property.name"
              [ngClass]="{ errorInput: frontErrors.name != '' }"
              (change)="isValidate('name')" />
            <div class="invalid-feedback" *ngIf="frontErrors.name != ''">{{
              frontErrors.name }}</div>
          </div>
          <div class="Property__Form__InputArea">
            <label for="amount">{{ 'globals.amount' | translate }} <sup>*</sup></label>
            <div class="amount"
              [ngClass]="{ errorInput: frontErrors.amount != '' }">
              <input
                type="number"
                name="amount"
                [(ngModel)]="property.amount"
                (change)="isValidate('amount'); onAmountChange($event)" />
              <span id="basic-addon2">EUR</span>
            </div>
            <div class="invalid-feedback" *ngIf="frontErrors.amount != ''">{{
              frontErrors.amount }}</div>
          </div>
        </div>
        <div class="Property__Form__InputArea">
          <label for="reason"> {{ 'pages.createAsset.reasonOfRequest' |
            translate }}<sup>*</sup> </label>
          <textarea
            type="text"
            name="reason"
            [(ngModel)]="property.reason"
            (change)="isValidate('reason')"
            [ngClass]="{ errorInput: frontErrors.reason != '' }"></textarea>
          <span class="input-length"
            [ngClass]="{'error' : property?.reason ? (property?.reason.length > 500) : false}">
            {{ property.reason ? property.reason.length : 0 }}/500
          </span>
          <div class="invalid-feedback" *ngIf="frontErrors.reason != ''">{{
            frontErrors.reason }}</div>
        </div>
        <div class="Property__Form__InputArea">
          <label for="idArea">{{'pages.assets.where_idArea' | translate}} <sup
              *ngIf="property.type === PropertyType.MULTIPLE">*</sup>
            <span class="infos">
              <app-informations-component
                [text]="'pages.assets.informationAsked_idArea' | translate"></app-informations-component>
            </span>
          </label>
          <input
            type="text"
            id="idArea"
            name="idArea"
            (change)="isValidate('idArea')"
            [(ngModel)]="property.idArea"
            [ngClass]="{ errorInput: frontErrors.idArea != '' }" />
          <div class="invalid-feedback" *ngIf="frontErrors.idArea != ''">{{
            frontErrors.idArea }}</div>
        </div>
      </form>
      <form #PropertyCIform="ngForm" class="Property__Form">
        <div class="Property__Form__Title">
          <h1>{{ 'pages.assets.complementary_information_title' | translate }}</h1>
        </div>
        <div class="Property__Form__PrimaryArea">
          <div class="Property__Form__InputArea">
            <label for="reference">{{'globals.internReference' | translate}}</label>
            <input type="text" name="reference" [(ngModel)]="property.reference"
              id="reference" />
          </div>
          <div class="Property__Form__InputArea">
            <label for="datesOptional">
              {{ 'pages.createBond.optionalDates' | translate }}
              <span class="infos">
                <app-informations-component
                  [text]="'pages.assets.optionalDatesTooltip' | translate"></app-informations-component>
              </span></label>
            <app-boolean-form
              [lightUI]="true"
              name="datesOptional"
              [(ngModel)]="property.datesOptional"></app-boolean-form>
          </div>
        </div>
        <div class="Property__Form__PrimaryArea">
          <div class="Property__Form__InputArea">
            <label for="vigilance"> {{ 'pages.createBond.vigilancePoints' |
              translate }} </label>
            <input type="text" name="vigilance"
              [(ngModel)]="property.vigilancePoints" id="vigilance" />
          </div>
          <div class="Property__Form__InputArea">
            <label for="remote">{{'pages.createBond.remoteState' | translate}}</label>
            <app-boolean-form
              name="remote"
              id="remote"
              [lightUI]="true"
              [(ngModel)]="property.remoteCheckInOut"></app-boolean-form>
          </div>
        </div>
        <div class="Property__Form__InputArea">
          <label for>{{ 'pages.assets.documentToSend' | translate }}</label>
          <app-document
            [fileDropedStatus]="documentStatusEnum.DROPED"
            *ngFor="let doc of docs"
            [document]="doc"
            [errors]="documentsErrors"
            (fileUploaded)="onFileUploaded(doc.type)"></app-document>
        </div>
        <div class="Property__Form__InputArea">
          <label for="welcomeMessage">{{ 'pages.createBond.welcomeMessage' |
            translate }}
            <span class="infos">
              <app-informations-component
                [text]="'pages.createBond.welcomeMessage_tooltip' | translate"></app-informations-component>
            </span>
          </label>
          <textarea name="welcomeMessage" [(ngModel)]="property.welcomeMessage"
            id="welcomeMessage"></textarea>
          <span class="input-length"
            [ngClass]="{'error' : property?.welcomeMessage ? (property?.welcomeMessage.length > 500) : false}">
            {{ property.welcomeMessage ? property.welcomeMessage.length : 0
            }}/500
          </span>
        </div>
        <div class="Property__Form__InputArea">
          <label for="confirmMessage">{{ 'pages.createBond.confirmMessage' |
            translate }}
            <span class="infos">
              <app-informations-component
                [text]="'pages.createBond.confirmMessage_tooltip' | translate"></app-informations-component>
            </span>
          </label>
          <textarea name="confirmMessage" [(ngModel)]="property.confirmMessage"
            id="confirmMessage"></textarea>
          <span class="input-length"
            [ngClass]="{'error' : property?.confirmMessage ? (property?.confirmMessage.length > 500) : false}">
            {{ property.confirmMessage ? property.confirmMessage.length : 0
            }}/500
          </span>
        </div>
      </form>
      <div class="actions">
        <app-load-button
          label="{{ 'actions.save' | translate }}"
          [loading]="loading"
          [disabled]="loading"
          (click)="onSubmit()"></app-load-button>
      </div>
    </div>
    <div class="Property__BondListArea mt-5" *ngIf="onglet === 2">
      <app-bond [bond]="bondList"></app-bond>
      <app-pagination
        *ngIf="count.count > 6"
        (selected)="updatePage($event)"
        [page]="page"
        [count]="count.count"
        [items]="6">
      </app-pagination>
      <app-empty
        *ngIf="!loading && !bondList.length"
        icon="fa-treasure-chest"
        label="{{'globals.newBond' | translate}}"
        [title]="'Vous n\'avez aucune caution lié à ce bien'"
        class="mt-5"></app-empty>
    </div>
  </div>
</div>
