import { Injectable } from '@angular/core';
import { AbstractRequest } from '@shared/requests/AbstractRequest';
import Bond from '@shared/models/Bond';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import BondCreate from '@shared/models/BondCreate';
import Count from '@shared/models/Count';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BondService extends AbstractRequest {
  public fetchAll(
    page?: number,
    items?: number,
    state: Array<string> | null = null,
    type?: string | null,
    sortBy?: string | null,
    sortDirection?: string | null,
    search?: string | null,
    startDate?: string | null,
    endDate?: string | null
  ): Promise<Bond[]> {
    const names = ['page', 'items', 'state', 'type', 'sortBy', 'sortDirection', 'search', 'startDate', 'endDate'];
    const args = this.formatForStateArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
      .get<Bond[]>(`${this.endpoint}/bonds/owner`, {
        params,
      })
      .toPromise();
  }

  public count(
    state: Array<string> | null = null,
    type?: string | null,
    sortBy?: string,
    sortDirection?: string,
    search?: string,
    startDate?: string,
    endDate?: string
  ): Promise<Count> {
    const names = ['state', 'type', 'sortBy', 'sortDirection', 'search', 'startDate', 'endDate'];
    const args = this.formatForStateArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
      .get<Count>(`${this.endpoint}/bonds/owner/count`, {
        params,
      })
      .toPromise();
  }

  public userFetchAll(
    page?: number,
    items?: number,
    state: string | null = null,
    type?: string | null,
    sortBy?: string | null,
    sortDirection?: string | null,
    search?: string | null,
    startDate?: string | null,
    endDate?: string | null
  ): Promise<Bond[]> {
    const names = ['page', 'items', 'state', 'type', 'sortBy', 'sortDirection', 'search', 'startDate', 'endDate'];
    const args = this.formatArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
      .get<Bond[]>(`${this.endpoint}/bonds/user`, {
        params,
      })
      .toPromise();
  }

  public userCount(
    state: string | null = null,
    type?: string | null,
    sortBy?: string,
    sortDirection?: string,
    search?: string,
    startDate?: string,
    endDate?: string
  ): Promise<Count> {
    const names = ['state', 'type', 'sortBy', 'sortDirection', 'search', 'startDate', 'endDate'];
    const args = this.formatArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
      .get<Count>(`${this.endpoint}/bonds/user/count`, {
        params,
      })
      .toPromise();
  }

  public create(bond: BondCreate): Promise<Bond> {
    return this.http.post<Bond>(`${this.endpoint}/bonds`, bond).toPromise();
  }

  public update(bond: BondCreate | Bond, id: string): Promise<Bond> {
    return this.http.put<Bond>(`${this.endpoint}/bonds/${id}`, bond).toPromise();
  }

  public updateForTransaction(bond: BondCreate | Bond, id: string): Promise<Bond> {
    return this.http.put<Bond>(`${this.endpoint}/bonds/${id}/transaction`, bond).toPromise();
  }

  public finish(id: string | number): Promise<void> {
    return this.http.get<void>(`${this.endpoint}/bonds/${id}/finish`).toPromise();
  }

  public qrcode(id: number): Promise<any> {
    return this.http.get<any>(`${this.endpoint}/bonds/${id}/qrcode`).toPromise();
  }

  public fetch(id: string, who = 'owner'): Promise<Bond> {
    return this.http.get<Bond>(`${this.endpoint}/bonds/${who}/${id}`).toPromise();
  }

  public fetchByProperty(id: number): Promise<Bond> {
    return this.http.get<Bond>(`${this.endpoint}/bonds/property/${id}`).toPromise();
  }

  public fetchBondListByProperty(
    page: number,
    items: number,
    state: string | null = null,
    type: string | null,
    sortBy: string | null,
    sortDirection: string | null,
    search: string | null,
    startDate: string | null,
    endDate: string | null,
    id: number
  ): Promise<Bond[]> {
    const names = ['page', 'items', 'state', 'type', 'sortBy', 'sortDirection', 'search', 'startDate', 'endDate'];
    const args = this.formatArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
      .get<Bond[]>(`${this.endpoint}/bonds/property/list/${id}`, {
        params,
      })
      .toPromise();
  }

  public countByProperty(
    state: string | null = null,
    type: string | null,
    sortBy: string,
    sortDirection: string,
    search: string,
    startDate: string,
    endDate: string,
    id: number,
  ): Promise<Count> {
    const names = ['state', 'type', 'sortBy', 'sortDirection', 'search', 'startDate', 'endDate'];
    const args = this.formatArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
      .get<Count>(`${this.endpoint}/bonds/property/${id}/count`, {
        params,
      })
      .toPromise();
  }

  public cancel(id: number | string): Promise<void> {
    return this.http.delete<void>(`${this.endpoint}/bonds/${id}`).toPromise();
  }

  public retry(id: string): Promise<void> {
    return this.http.get<void>(`${this.endpoint}/bonds/${id}/retry`).toPromise();
  }

  public read(id: string): Promise<Bond> {
    return this.http.get<Bond>(`${this.endpoint}/bonds/${id}`).toPromise();
  }

  public zip(id: number): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.endpoint}/bonds/${id}/zip`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
