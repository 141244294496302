import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AbstractRequest {
  protected api: string;

  protected endpoint: string;

  constructor(protected http: HttpClient) {
    this.api = environment.backend;
    this.endpoint = `${environment.backend}/${environment.version}`;
  }

  protected formatArgs(names: string[], args: IArguments): { [k: string]: any } {
    const a: { [k: string]: any } = {};

    for (let i = 0; i < args.length; ++i) {
      if (args[i]) {
        a[names[i]] = args[i];
      }
    }

    return a;
  }

  protected formatForStateArgs(names: string[], args: IArguments): { [k: string]: any } {
    const a: { [k: string]: any } = {};

    for (let i = 0; i < names.length; ++i) {
      const paramName = names[i];
      const paramValue = args[i];

      if (paramValue !== undefined && paramValue !== null) {
        if (paramName === 'state' && Array.isArray(paramValue)) {
          a[paramName] = `${paramValue.join(',')}`;
        } else {
          a[paramName] = paramValue;
        }
      }
    }

    return a;
  }


}
