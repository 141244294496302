import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from '@auth/services/token-storage.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class OwnerGuard implements CanActivate {
  public constructor(private router: NavController, private tokenStorage: TokenStorageService) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve) => {
      this.tokenStorage
        .loadToken()
        .then(() => {
          if (this.tokenStorage.getRoles().find((r) => r === 'ROLE_OWNER') === undefined) {
            this.router.navigateRoot('/user/dashboard');
            resolve(false);
          }

          resolve(true);
        })
        .catch(() => {
          this.router.navigateRoot('/user/dashboard');
          resolve(false);
        });
    });
  }
}
