import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonDatetime, IonicModule } from '@ionic/angular';
import { StatItemComponent } from '@shared/components/stat-item/stat-item.component';
import { MomentModule } from 'angular2-moment';
import { SortableItemComponent } from '@shared/components/sortable-item/sortable-item.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { ClipboardModule } from 'ngx-clipboard';
import { BooleanFormComponent } from '@shared/form/boolean-form/boolean-form.component';
import { DateInputComponent } from '@shared/form/date-input/date-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserComponent } from '@shared/components/user/user.component';
import { DropFileDirective } from '@shared/directives/drop-file.directive';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { FileInputComponent } from '@shared/components/file-input/file-input.component';
import { BondComponent } from '@shared/components/items/bond/bond.component';
import { LinkComponent } from '@shared/components/items/link/link.component';
import { TransactionComponent } from '@shared/components/items/transaction/transaction.component';
import { TylltComponent } from '@shared/components/tyllt/tyllt.component';
import { PlaceholderComponent } from '@shared/components/items/placeholder/placeholder.component';
import { LinkPlaceholderComponent } from '@shared/components/items/link-placeholder/link-placeholder.component';
import { DocumentComponent } from '@shared/components/document/document.component';
import { LoadButtonComponent } from '@shared/components/load-button/load-button.component';
import { KeyboardEnterDirective } from './directives/keyboard-enter.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { AbstractIonInitPage } from '@shared/abstract/AbstractIonInitPage';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyComponent } from '@shared/components/empty/empty.component';
import { PropertyComponent } from '@shared/components/items/property/property.component';
import { SearchModalComponent } from '@shared/components/search-modal/search-modal.component';
import { DeleteCardComponent } from './modals/delete-card/delete-card.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { LangPickerComponent } from './components/lang-picker/lang-picker.component';
import {InformationsComponent} from '@shared/components/informations/informations.component';
import { GeneralComponent } from './components/profil/general/general.component';
import { LegalDocumentComponent } from './components/profil/legal-document/legal-document.component';
import { SettingsComponent } from './components/profil/settings/settings.component';
import { BankDetailsComponent } from './components/profil/bank-details/bank-details.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgxMaskModule} from 'ngx-mask';
import { BandeauComponent } from './components/bandeau/bandeau.component';
import { MenuProfilComponent } from './components/profil/menu/menu-profil.component';

@NgModule({
  declarations: [
    AbstractIonInitPage,
    StatItemComponent,
    SortableItemComponent,
    PaginationComponent,
    BooleanFormComponent,
    DateInputComponent,
    UserComponent,
    DropFileDirective,
    FileUploadComponent,
    FileInputComponent,
    BondComponent,
    LinkComponent,
    TransactionComponent,
    TylltComponent,
    PlaceholderComponent,
    LinkPlaceholderComponent,
    DocumentComponent,
    LoadButtonComponent,
    KeyboardEnterDirective,
    TooltipDirective,
    EmptyComponent,
    PropertyComponent,
    SearchModalComponent,
    DeleteCardComponent,
    DragDropDirective,
    LangPickerComponent,
    InformationsComponent,
    LegalDocumentComponent,
    SettingsComponent,
    GeneralComponent,
    BankDetailsComponent,
    BandeauComponent,
    MenuProfilComponent
  ],
  exports: [
    StatItemComponent,
    SortableItemComponent,
    PaginationComponent,
    BooleanFormComponent,
    DateInputComponent,
    UserComponent,
    DropFileDirective,
    FileUploadComponent,
    FileInputComponent,
    BondComponent,
    LinkComponent,
    TransactionComponent,
    TylltComponent,
    PlaceholderComponent,
    LinkPlaceholderComponent,
    DocumentComponent,
    LoadButtonComponent,
    KeyboardEnterDirective,
    TooltipDirective,
    EmptyComponent,
    PropertyComponent,
    SearchModalComponent,
    DeleteCardComponent,
    DragDropDirective,
    LangPickerComponent,
    InformationsComponent,
    LegalDocumentComponent,
    SettingsComponent,
    GeneralComponent,
    BankDetailsComponent,
    BandeauComponent,
    MenuProfilComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    MomentModule,
    ClipboardModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    Ng2FlatpickrModule,
    NgSelectModule,
    NgxMaskModule,
  ],
})
export class SharedModule {}
