import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-load-button',
  templateUrl: './load-button.component.html',
  styleUrls: ['./load-button.component.scss'],
})
export class LoadButtonComponent implements OnInit {
  @Input() public label!: string;

  @Input() public color = 'primary';

  @Input() public loading!: boolean;

  @Input() public disabled!: boolean;

  @Input() public type = 'submit';

  @Input() public class = '';

  constructor() {}

  ngOnInit() {}
}
