import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private status: { [k: string]: string } = {
    litigation: 'Litige',
    in_progress: 'En cours',
    validated: 'Validé',
    invalidated: 'Invalide',
    finish: 'Terminé',
    finished: 'Terminé',
    payed: 'En cours',
    waiting: 'En attente',
    negotiating: 'Négociation',
    waiting_approval: 'Déposé',
    droped: 'Déposé',
    file_error: 'Invalide',
    provide: 'À fournir',
    cancel: 'Annulé',
    created: 'En attente de validation',
    cashOut: 'Encaissement',
    failed: 'Paiement échoué',
    deny: 'Refusé',
    accepted: 'Accepté'
  };

  public trans(key: string): string {
    return this.status[key];
  }
}
