import { Component, EventEmitter, Input, Output } from '@angular/core';
import Document from '@shared/models/Document';
import { IFileUpload } from '@shared/components/file-upload/file-upload.component';
import Media, { VISIBILITY_PRIVATE } from '@shared/models/Media';
import { MediaService } from '@shared/requests/media.service';
import { ToastService } from '@shared/services/toast.service';
import { DocumentService } from '@shared/translations/document.service';
import { StatusService } from '@shared/translations/status.service';
import { DocumentRequest } from '@shared/requests/document.service';
import { FILE_TYPE } from '@shared/constant/mime_format.constant';
import { Camera, CameraResultType, CameraSource } from '@capacitor/core';
import { getFileFromBase64 } from '@shared/functions/get-file-from-b64.function';
import { DocumentsStatusEnum } from '@shared/enums/documents-status.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent {
  @Output() public fileUploaded = new EventEmitter<Media | boolean>();
  @Input() public document!: Document;
  @Input() public errors: string[] = [];
  @Input() public fileDropedStatus: DocumentsStatusEnum = DocumentsStatusEnum.WAITING_APPROVAL;
  /**
   * If component should accept multiple files.
   */
  @Input() public isMultiple = true;

  public documentStatusEnum = DocumentsStatusEnum;

  public translate!: TranslateService;

  constructor(
    public statusService: StatusService,
    public documentService: DocumentService
  ) { }
}
