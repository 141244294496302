import { PropertyDetailsRoutingModule } from './property-details-routing.module';
import { PropertyDetailsPage } from './property-details.page';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {SharedModule} from '@shared/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [PropertyDetailsPage],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PropertyDetailsRoutingModule,
    SharedModule,
    NgSelectModule,
    TranslateModule,
  ]
})
export class PropertyDetailsModule { }
