import { Injectable } from '@angular/core';
import { AbstractRequest } from '@shared/requests/AbstractRequest';

@Injectable({
  providedIn: 'root',
})
export class DocumentRequest extends AbstractRequest {
  public delete(id: number): Promise<void> {
    return this.http.delete<void>(`${this.endpoint}/documents/${id}`).toPromise();
  }
}
